import { render, staticRenderFns } from "./HeaderButtonMenuAccount.vue?vue&type=template&id=7aba3ba8&scoped=true&"
import script from "./HeaderButtonMenuAccount.vue?vue&type=script&lang=js&"
export * from "./HeaderButtonMenuAccount.vue?vue&type=script&lang=js&"
import style0 from "./HeaderButtonMenuAccount.vue?vue&type=style&index=0&id=7aba3ba8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aba3ba8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,BaseSheet: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseSheet.vue').default})
